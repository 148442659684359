import {Box, Button} from "@material-ui/core";
import spolexLogoTrimmed from "../../assets/images/spolexLogoTrimmed.png";
import {ExitToApp} from "@material-ui/icons";
import React, { Fragment } from "react";
import {useHistory} from "react-router-dom";


const Header = () => {
  const history = useHistory();


  const onLogOut = () => {
    localStorage.removeItem('token');

    history.push('/login');
  };

  return (
    <Fragment>
      <Box p={[1, 2, 3]} display='flex' justifyContent='space-between' alignItems='center'>
        <Box maxHeight={['20px', '25px', '50px']} onClick={() => window.location.reload()}>
          <img src={spolexLogoTrimmed} alt='logo' style={{maxHeight: 'inherit'}} />
        </Box>
        <Button
          style={{margin: 0}}
          onClick={onLogOut}
          startIcon={<ExitToApp/>}
        >
          Wyloguj
        </Button>
      </Box>
      <div style={{width: '100%', height: '1px', boxShadow: '0px -1px 2px rgba(0, 0, 0, 0.25)'}} />
    </Fragment>
  )
}

export default Header
