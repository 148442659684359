import React, {Fragment} from 'react'
import {Box, Button, Grid} from "@material-ui/core";
import {COLOR, DEPARTURES_STATUS_COLORS as STATUS_COLORS} from "../../theme";
import {useTheme} from "@material-ui/core/styles";
import {format, toDate, getISODay, parseISO} from 'date-fns'
import {WEEKDAYS} from "../../helpers/utils";
import {useHistory} from "react-router-dom";

const DepartureTile = ({record}) => {

  const theme = useTheme()
  const history = useHistory()

  return (
    <div style={{background: STATUS_COLORS[record.status] || COLOR.primary, borderRadius: '8px', marginBottom: '24px'}}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <h3 style={{color: '#fff', margin: 0}}>{record.contractorName}</h3>
              <h3 style={{color: '#fff', margin: 0}}>
                {
                  record.startDate === record.endDate
                    ? `${WEEKDAYS[getISODay(toDate(parseISO(record.startDate)))]} ${format(toDate(parseISO(record.startDate)), 'dd.MM.yyyy')}`
                    : `${WEEKDAYS[getISODay(toDate(parseISO(record.startDate)))]} ${format(toDate(parseISO(record.startDate)), 'dd.MM.yyyy')} - ${WEEKDAYS[getISODay(toDate(parseISO(record.endDate)))]} ${format(toDate(parseISO(record.endDate)), 'dd.MM.yyyy')}`
                }
              </h3>
            </Grid>
            <Grid item xs={12}>
              <Box bgcolor={theme.palette.background.default} borderRadius='0 0 8px 8px' border={`1px solid ${STATUS_COLORS[record.status] || COLOR.primary}`}>
                <Grid container>
                  <Grid item xs={6}>
                    <p style={{textAlign: 'left', paddingLeft: '16px', margin: '5px 0 0'}}>Osoby zaangażowane</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p style={{textAlign: 'left', paddingLeft: '16px', margin: '5px 0 0'}}>{record.owners || '-'}</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p style={{textAlign: 'left', paddingLeft: '16px', margin: '5px 0 0'}}>Typ</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p style={{textAlign: 'left', paddingLeft: '16px', margin: '5px 0 0'}}>{record.type}</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p style={{textAlign: 'left', paddingLeft: '16px', margin: '5px 0 0'}}>Status</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p style={{textAlign: 'left', paddingLeft: '16px', margin: '5px 0 0'}}>{record.status}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display='flex' justifyContent='center'>
                      <Box width='50%' p={2}>
                        <Button
                          style={{marginTop: '5px'}}
                          fullWidth
                          color='primary'
                          variant='contained'
                          onClick={() => history.push(`/harmonogram/${record.id}`)}
                        >
                          Więcej
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default DepartureTile
