import {useSelector} from "react-redux";
import {departuresSelector} from "../Departures/DeparturesSlice";
import {Fade, Grid} from "@material-ui/core";
import DepartureTile from "../../common/Components/DepartureTile";
import {COLOR} from "../../theme";

const Timetable = () => {

  const {recordsList} = useSelector(departuresSelector)

  const filtered = recordsList.filter(record => record.status !== 'Zamknięty')

  return (
    <Fade in>
      <Grid container>
        <Grid item xs={12}>
          {
            filtered.length > 0
              ? filtered.map(record => {
                return <DepartureTile key={record.id} record={record} />
              })
              : <p style={{textAlign: 'center'}}>Brak zaplanowanych wyjazdów</p>
          }
          {/*<DepartureTile color={COLOR.success} />*/}
          {/*<DepartureTile color={COLOR.warning} />*/}
        </Grid>
      </Grid>
    </Fade>
  )
}

export default Timetable
