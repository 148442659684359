import axios from 'axios'

const api = axios.create({
  baseURL: 'https://spolexcrm.crmexpert.co/webservice',
  // baseURL: 'https://wojtek.crmexpert.co/webservice',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Basic ${process.env.REACT_APP_X_API_TOKEN}`,
    'X-API-KEY': process.env.REACT_APP_X_API_KEY,
  }
})

api.interceptors.response.use(
  res => res,
  error => {

    if (error.response?.status === 401) {
      window.location = '/login'
    }

    return Promise.reject(error)
  }
)

export { api }
