import React, {useEffect, useState} from 'react'
import {COLOR, DEPARTURES_STATUS_COLORS as STATUS_COLORS} from "../../theme";
import {Box, Grid} from "@material-ui/core";
import {WEEKDAYS} from "../../helpers/utils";
import {format, getISODay, toDate, parseISO} from "date-fns";
import {useHistory, useParams, NavLink, Switch, Route} from 'react-router-dom'
import {useDispatch} from "react-redux";
import {getDeparture} from "../../features/Departures/DeparturesSlice";
import {ArrowBack} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import DepartureOverview from "./Overview";
import Note from "./Note";

const Departure = () => {

  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const fetchDeparture = () => {
    const {id} = params

    dispatch(getDeparture(id)).then(res => setRecord(res.payload))
  }

  useEffect(() => {
    fetchDeparture()
  }, [])

  const [record, setRecord] = useState(null)

  if (!record) {
    return null
  }

  return (
    <>
      <div style={{background: STATUS_COLORS[record.status] || COLOR.primary, borderRadius: '8px', margin: '16px 16px 0'}}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Box position='relative' height='100%'>
                  <div
                    style={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      top: 0,
                      left: 0}}
                  >
                    <IconButton
                      style={{backgroundColor: 'transparent', color: '#FFF'}}
                      aria-label="back-button"
                      onClick={() => history.go(-1)}
                    >
                      <ArrowBack />
                    </IconButton>
                  </div>
                  <h3 style={{color: '#fff', margin: '0 48px 0'}}>{record.contractorName}</h3>
                  <h3 style={{color: '#fff', margin: '0 48px 0'}}>
                    {
                      record.startDate === record.endDate
                        ? `${WEEKDAYS[getISODay(toDate(parseISO(record.startDate)))]} ${format(toDate(parseISO(record.startDate)), 'dd.MM.yyyy')}`
                        : `${WEEKDAYS[getISODay(toDate(parseISO(record.startDate)))]} ${format(toDate(parseISO(record.startDate)), 'dd.MM.yyyy')} - ${WEEKDAYS[getISODay(toDate(parseISO(record.endDate)))]} ${format(toDate(parseISO(record.endDate)), 'dd.MM.yyyy')}`
                    }
                  </h3>
                </Box>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </div>
      <div style={{margin: '0 16px 16px'}}>
        <Switch>
          <Route exact path='/harmonogram/:id' render={() => <DepartureOverview departure={record} />} />
          <Route exact path='/harmonogram/:id/:noteType/:machineId/:mode/:id?' render={() => <Note fetchDeparture={fetchDeparture} departure={record} />} />
        </Switch>
      </div>
    </>
  )
}

export default Departure
