import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Login from './features/User/Login';
import Dashboard from './features/User/Dashboard';
import {PrivateRoute} from './helpers/PrivateRoute';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@material-ui/core";
import Scheduler from "./features/Scheduler/scheduler.js";
import {Check, Close} from "@material-ui/icons";

function App() {

  // const [confirmationDialog, setConfirmationDialog] = useState(true)

  return (
    <div className="App">
      {/*<Dialog open={confirmationDialog}>*/}
      {/*  <Box width='100%' display='flex' justifyContent='flex-end' alignItems='center'>*/}
      {/*    <IconButton onClick={() => setConfirmationDialog(false)}>*/}
      {/*      <Close />*/}
      {/*    </IconButton>*/}
      {/*  </Box>*/}
      {/*  <DialogTitle>*/}
      {/*    <span>UWAGA</span>*/}
      {/*  </DialogTitle>*/}
      {/*  <DialogContent>*/}
      {/*    Kończy się miejsce na dysku.<br/>*/}
      {/*    Skontaktuj się z administratorem.*/}
      {/*  </DialogContent>*/}
      {/*  <DialogActions>*/}
      {/*    <Button variant='contained' onClick={() => setConfirmationDialog(false)} color="primary" autoFocus startIcon={<Close />}>*/}
      {/*      Zamknij*/}
      {/*    </Button>*/}
      {/*  </DialogActions>*/}
      {/*</Dialog>*/}
      {/*<Typography variant='body1'>*/}
        <Router>
          <Switch>
            <Route exact component={Login} path={`/login`} />
            <Route exact component={Scheduler} path={`/planowanie`} />
            <PrivateRoute component={Dashboard} path={`/`} />
          </Switch>
        </Router>
      {/*</Typography>*/}
    </div>
  );
}

export default App;
