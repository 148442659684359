import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
  Box,
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  Grid,
  Grow,
  makeStyles,
  TextField
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import {
  AddAPhoto,
  Check,
  Close,
  DeleteOutlined
} from "@material-ui/icons";
import {api} from "../../api";
import {TransitionGroup} from "react-transition-group";
import {toast} from "react-toastify";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  // margin: {
  //   marginTop: theme.spacing(2),
  //   marginBottom: theme.spacing(2),
  // },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
    background: '#FFF'
  },
}));

const schema = () =>
  yup.object({
    content: yup.string()
      .required('To pole jest obowiązkowe')
      .min(60, 'Notatka musi być dłuższa. Opisz wyjazd bardziej dokładnie.'),
  });


const Note = ({fetchDeparture, departure}) => {
  const params = useParams()
  const history = useHistory();
  const classes = useStyles();
  const [ note, setNote ] = useState(null)
  const [ files, setFiles ] = useState([])
  const [ fetchedFiles, setFetchedFiles ] = useState([])
  const [ machine, setMachine ] = useState(null)
  const [ loaded, setLoaded ] = useState(false)
  const [ filesLoaded, setFilesLoaded ] = useState(true)
  const [ confirmationDialog, setConfirmationDialog ] = useState(false)
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [ imagePreview, setImagePreview ] = useState(false)

  const { register, errors, watch, handleSubmit, control, getValues, setValue } = useForm({
    resolver: yupResolver(schema()),
  });

  console.log(errors)

  const { mode, noteType, machineId, id } = params

  const isMachineNote = noteType === 'machine-note'
  const isGeneralNote = noteType ==='general-note'

  const isEditMode = mode === 'edit'

  useEffect(() => {
    if (isMachineNote && machineId) {
      const thisMachine = departure.machines.find(machine => machine.id === parseInt(machineId))

      setMachine(thisMachine)
    }

    if (isEditMode && id) {
      api.get(
        `/Notes/Record/${id}`,
        {
          headers: {
            'X-TOKEN': localStorage.getItem('token')
          }
        }
      ).then(res => {
        const fetchedNote = res.data.result

        setValue('content', fetchedNote.content)
        setNote(fetchedNote)
        setLoaded(true)

        if (fetchedNote?.files.length > 0) {
          setFilesLoaded(false)
          Promise.all(fetchedNote.files.map(file => {
            return api.get(
              `/Documents/Record/${file.id}`,
              {
                headers: {
                  'X-TOKEN': localStorage.getItem('token')
                }
              }
            ).then(res => ({...file, url: res?.data?.result}))
          })).then(res => {
            setFetchedFiles(res)
            setFilesLoaded(true)
          })
        } else {
          setFilesLoaded(true)
        }
      })
    } else {
      setLoaded(true)
    }
  }, [])

  const handleCapture = ({ target }) => {
    const newFiles = [...target.files]

    setFiles([...files, ...newFiles])
    target.value = null
  }

  const onSubmit = (values) => {

    setIsSubmitting(true)

    const data = {
      content: values.content,
      contractorId: departure.contractorId,
      machineId: machineId ? parseInt(machineId) : 0,
      departureId: departure.id,
    }

    api.request(
      {
        url: isEditMode ? `/Notes/Record/${id}` : '/Notes/Record',
        method: isEditMode ? 'PUT' : 'POST',
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        },
        data: data
      }
    ).then(res => {

      const noteId = note?.id || res?.data?.result?.id

      if (noteId && files.length > 0) {
        return Promise.all(files.map(file => {
          let formData = new FormData();

          formData.append("filename", file)
          formData.append("fileTitle", file.name)
          formData.append("noteId", noteId)

          return api.post("/Documents/Record", formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Accept': 'multipart/form-data',
              'X-TOKEN': localStorage.getItem('token')
            },
          }).catch(error => {
            setIsSubmitting(false)
            toast.error(`Nie udało się dodać zdjęcia ${file.name}.`)
          });
        })).then((res) => {
          setIsSubmitting(false)
          fetchDeparture()
          history.go(-1)
          toast.success('Zapisano zmiany')
        })
      } else {
        setIsSubmitting(false)
        fetchDeparture()
        history.go(-1)
        toast.success('Zapisano zmiany')
      }
    }).catch(() => {
      toast.error('Nie udało się zapisać notatki.')
    })
  }

  const deleteFile = (id) => {
    return api.request({
      url: `/Documents/Record/${id}`,
      method: 'DELETE',
      headers: {
        'X-TOKEN': localStorage.getItem('token')
      }
    })
  }

  const deleteNote = () => {
    api.request({
      url: `/Notes/Record/${id}`,
      method: 'DELETE',
      headers: {
        'X-TOKEN': localStorage.getItem('token')
      }
    }).then(() => {
      setConfirmationDialog(false)
      toast.warning('Notatka usunięta')
      fetchDeparture()
      history.go(-1)
    })
  }

  return (
    <Fade in={loaded}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        method='POST'
      >
        <Grid container>
          <Grid item xs={12}>
            <div style={{position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <h4 style={{maxWidth: '75%'}}>
                {isGeneralNote && 'Notatka ogólna'}
                {isMachineNote && machine && `Notatka - ${machine.name}`}
              </h4>
              {isEditMode && <div
                style={{position: "absolute", height: '100%', top: 0, right: 0, display: 'flex', alignItems: 'center'}}>
                <IconButton
                  style={{backgroundColor: 'transparent'}}
                  onClick={() => setConfirmationDialog(true)}
                  size='large'
                >
                  <DeleteOutlined/>
                </IconButton>
              </div>}
            </div>
            <FormControl required className={clsx(classes.margin, classes.textField)} variant='outlined'>
              <Controller
                name='content'
                required
                control={control}
                type='text'
                defaultValue=''
                as={
                  <TextField
                    required
                    label='Treść notatki'
                    variant='outlined'
                    multiline
                    error={errors?.content}
                    rows={5}
                    rowsMin={5}
                    rowsMax={20}
                    labelWidth={85}
                  />
                }
              />
            </FormControl>
            <p style={{textAlign:'left', color: '#f44336'}}>
              {errors?.content
                ? errors.content.message
                : ''
              }
            </p>
          </Grid>
          <Grid item xs={12}>
            <Box mt={1} mb={1}>
              {!filesLoaded && <Box mt={5}><CircularProgress /></Box>}
              <TransitionGroup>
                {
                  filesLoaded && fetchedFiles.map((file, index) => {
                    return (
                      <Grow key={index}>
                        <Grid container alignItems='center'>
                          <Grid item xs={3}>
                            <img onClick={() => setImagePreview(`data:image;base64, ${file.url}`)} style={{maxWidth: '100%', borderRadius: '8px'}} alt={file.name} src={`data:image;base64, ${file.url}`} />
                          </Grid>
                          <Grid item xs={6}>
                            <p style={{textAlign: 'left', marginLeft: '16px', wordBreak: 'break-all'}}>
                              {file.name}
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <Box display='flex' justifyContent='flex-end'>
                              <IconButton
                                style={{backgroundColor: 'transparent'}}
                                onClick={() => {
                                  const filtered = fetchedFiles.filter((file, fileIndex) => index !== fileIndex)
                                  deleteFile(file.id).then(res => {
                                    setFetchedFiles(filtered)
                                    toast.warning('Zdjęcie usunięte')
                                  })
                                }}
                                size='large'
                              >
                                <DeleteOutlined />
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grow>
                    )
                  })
                }
                {
                  files.map((file, index) => {
                    const image = URL.createObjectURL(file)
                    return (
                      <Grow key={index}>
                        <Grid container alignItems='center'>
                          <Grid item xs={3}>
                            <img onClick={() => setImagePreview(image)} style={{maxWidth: '100%', borderRadius: '8px'}} alt={file.name} src={image} />
                          </Grid>
                          <Grid item xs={6}>
                            <p style={{textAlign: 'left', marginLeft: '16px', wordBreak: 'break-all'}}>
                              {file.name}
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <Box display='flex' justifyContent='flex-end'>
                              <IconButton
                                style={{backgroundColor: 'transparent'}}
                                onClick={() => {
                                  const filtered = files.filter((file, fileIndex) => index !== fileIndex)
                                  setFiles(filtered)
                                }}
                                size='large'
                              >
                                <DeleteOutlined />
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grow>
                    )
                  })
                }
              </TransitionGroup>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant='outlined'
              component='label'
              startIcon={<AddAPhoto />}
            >
              <input
                type='file'
                accept='.jpg, .jpeg, .png'
                hidden
                multiple
                onChange={handleCapture}
              />
              Dodaj zdjęcie
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Box mr={1}>
              <Button
                variant='outlined'
                fullWidth
                onClick={() => history.go(-1)}
                startIcon={<Close />}
              >
                Anuluj
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box ml={1}>
              <Button
                color='primary'
                variant='contained'
                type='submit'
                fullWidth
                disabled={isSubmitting}
                startIcon={!isSubmitting && <Check />}
              >
                {isSubmitting ? <CircularProgress size={'26px'}/> : 'Zapisz'}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Dialog open={imagePreview} onClose={() => setImagePreview(null)}>
          <Box display='flex' justifyContent='flex-end'>
            <IconButton size='large' onClick={() => setImagePreview(null)}>
              <Close />
            </IconButton>
          </Box>
          <DialogContent>
            <img style={{height: 'auto', width: '100%'}} alt='preview' src={imagePreview}/>
          </DialogContent>
        </Dialog>
        <Dialog open={confirmationDialog} onClose={() => setConfirmationDialog(false)}>
          <DialogContent>
            Czy na pewno chcesz usunąć notatkę?
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={() => setConfirmationDialog(false)} color="primary" startIcon={<Close />}>
              Nie
            </Button>
            <Button variant='contained' onClick={deleteNote} color="primary" autoFocus startIcon={<Check />}>
              Tak
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </Fade>
  )
}

export default Note
