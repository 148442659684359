import {Fade, Grid} from "@material-ui/core";
import VacationTile from "../../common/Components/VacationTile";
import {COLOR} from "../../theme";
import {useSelector} from "react-redux";
import {vacationsSelector} from "../Vacations/VacationsSlice";

const Vacation = () => {

  const {recordsList} = useSelector(vacationsSelector)

  return (
    <Fade in>
      <Grid container>
        <Grid item xs={12}>
          {
            recordsList.length > 0 ? recordsList.map(record => {
              return <VacationTile key={record.id} record={record} />
            })
              : <p style={{textAlign: 'center'}}>Brak zaplanowanych urlopów</p>
          }
        </Grid>
      </Grid>
    </Fade>
  )
}

export default Vacation
