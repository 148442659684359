import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {api} from "../../api";

export const getDepartures = createAsyncThunk(
  'departures/recordsList',
  async (_, thunkAPI) => {
    try {
      const response = await api.request(
        {
          url: '/Departures/RecordsList',
          method: 'GET',
          headers: {
            'X-TOKEN': localStorage.getItem('token')
          }
        }
      );
      let data = response.data.result || []
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data.error);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.error);
    }
  }
);

export const getDeparture = createAsyncThunk(
  'departures/singleRecord',
  async (departureId, thunkAPI) => {
    try {
      const response = await api.request(
        {
          url: `/Departures/Record/${departureId}`,
          method: 'GET',
          headers: {
            'X-TOKEN': localStorage.getItem('token')
          }
        }
      );
      let data = response.data.result || []
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data.error);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.error);
    }
  }
);

export const departuresSlice = createSlice({
  name: 'departures',
  initialState: {
    recordsList: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
  },
  extraReducers: {
    [getDepartures.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.recordsList = payload
      return state;
    },
    [getDepartures.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getDepartures.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const departuresSelector = (state) => state.departures;
