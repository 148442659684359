import {Box, Button, Dialog, DialogActions, DialogContent, Grid, useMediaQuery} from "@material-ui/core";
import {NavLink, useHistory, useParams} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import {Add, Check, Close, Edit} from "@material-ui/icons";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useTheme} from "@material-ui/core/styles";
import {COLOR} from "../../theme";
import {format, toDate, parseISO} from "date-fns";
import {api} from "../../api";
import {getDepartures} from "../../features/Departures/DeparturesSlice";
import {toast} from "react-toastify";

const DepartureOverview = ({departure}) => {

  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()

  const matches1000 = useMediaQuery('(min-width:1000px)');
  const matches330 = useMediaQuery('(min-width:330px)');

  const [confirmationDialog, setConfirmationDialog] = useState(false)

  const closeDeparture = () => {
    api.put(
      `/Departures/Record/${departure.id}`,
      { status: 'Zamknięty' },
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        }
      }
    ).then(res => {
      dispatch(getDepartures()).then(() => {
        history.push('/harmonogram')
        setConfirmationDialog(false)
      })
    }).catch(error => {
      toast.error('Nie udało się przekazać do zamknięcia.')
      setConfirmationDialog(false)
    })

  }

  const overviewRowStyles = {
    textAlign: 'left',
    fontSize: matches1000
      ? '16px'
      : matches330
        ? '0.9rem'
        : '0.75rem',
    paddingLeft: '16px',
    margin: '5px 0 0'
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Box bgcolor={theme.palette.background.default}>
            <Grid container>
              <Grid item xs={5}>
                <p style={overviewRowStyles}>
                  Os. zaangażowane
                </p>
              </Grid>
              <Grid item xs={7}>
                <p style={overviewRowStyles}>
                  {departure.owners || '-'}
                </p>
              </Grid>
              <Grid item xs={5}>
                <p style={overviewRowStyles}>
                  Typ
                </p>
              </Grid>
              <Grid item xs={7}>
                <p style={overviewRowStyles}>
                  {departure.type}
                </p>
              </Grid>
              <Grid item xs={5}>
                <p style={overviewRowStyles}>
                  Status
                </p>
              </Grid>
              <Grid item xs={7}>
                <p style={overviewRowStyles}>
                  {departure.status}
                </p>
              </Grid>
              <Grid item xs={5}>
                <p style={overviewRowStyles}>
                  Adres
                </p>
              </Grid>
              <Grid item xs={7}>
                <p style={overviewRowStyles}>
                  {departure.street} {departure.buildingNumber}<br/>
                  {departure.postalCode} {departure.city}
                </p>
              </Grid>
              <Grid item xs={5}>
                <p style={overviewRowStyles}>
                  Os. kontaktowa
                </p>
              </Grid>
              <Grid item xs={7}>
                <p style={overviewRowStyles}>
                  {departure.contactPerson}<br/>
                  {departure.contactPhone && <a style={{color: COLOR.linkBlue, textDecoration: 'none'}} href={`tel: ${departure.contactPhone}`}>{departure.contactPhone}</a> }
                </p>
              </Grid>
              <Grid item xs={5}>
                <p style={overviewRowStyles}>
                  Opis
                </p>
              </Grid>
              <Grid item xs={7}>
                <p style={overviewRowStyles}>
                  {departure.description}
                </p>
              </Grid>
              {departure.machines.length > 0 && <Grid item xs={12}>
                <Box mt={2}>
                  <hr/>
                  <h4 style={{margin: '12px 0'}}>
                    Maszyny
                  </h4>

                  <Grid container>
                    {
                      departure.machines.map(machine => {
                        return (
                          <Grid item xs={12}>
                            <p style={{...overviewRowStyles, fontWeight: machine.note ? 'bold' : 'normal'}}>
                              <Box display='flex' justifyContent='space-between' alignItems='center'>
                                {
                                  machine.note
                                    ? <NavLink
                                      style={{textDecoration: 'none', color: COLOR.linkBlue}}
                                      to={`/harmonogram/${departure.id}/machine-note/${machine.id}/edit/${machine.note}`}>
                                      {machine.name}
                                  </NavLink>
                                    : <NavLink
                                      style={{textDecoration: 'none', color: COLOR.linkBlue}}
                                      to={`/harmonogram/${departure.id}/machine-note/${machine.id}/add`}>
                                      {machine.name}
                                    </NavLink>
                                }
                                <IconButton
                                  style={{backgroundColor: 'transparent'}}
                                  onClick={() => machine.note
                                    ? history.push(`/harmonogram/${departure.id}/machine-note/${machine.id}/edit/${machine.note}`)
                                    : history.push(`/harmonogram/${departure.id}/machine-note/${machine.id}/add`)
                                  }
                                  size='large'
                                  // aria-label="user"
                                  // edge='end'
                                >
                                  {machine.note ? <Edit /> : <Add />}
                                </IconButton>
                              </Box>
                            </p>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Box>
              </Grid>}
              <Grid item xs={12}>
                <Box mt={2}>
                  <hr/>
                  <h4 style={{margin: '12px 0'}}>
                    Notatki ogólne
                  </h4>
                  <Grid container>
                    {

                    }
                  </Grid>
                  {
                    departure.notes.length > 0
                      ? departure.notes.map(note => {
                      return (
                        <Grid item xs={12}>
                          <p style={{...overviewRowStyles}}>
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                              <NavLink
                                style={{textDecoration: 'none', color: COLOR.linkBlue}}
                                to={`/harmonogram/${departure.id}/general-note/0/edit/${note.id}`}>
                                {format(toDate(parseISO(note.createdDate)), 'dd.MM.yyyy')}, {format(toDate(parseISO(note.createdDate)), 'HH:mm')}, {note.owner}
                              </NavLink>
                              <IconButton
                                style={{backgroundColor: 'transparent'}}
                                onClick={() => history.push(`/harmonogram/${departure.id}/general-note/0/edit/${note.id}`)}
                                size='large'
                                // aria-label="user"
                                // edge='end'
                              >
                                <Edit />
                              </IconButton>
                            </Box>
                          </p>
                        </Grid>
                      )
                    })
                      : <p style={{...overviewRowStyles, padding: 0, textAlign: 'center'}}>Brak notatek ogólnych</p>
                  }
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2} display='flex' justifyContent='center'>
                  <Box width='100%'>
                    <Button
                      style={{marginTop: '5px'}}
                      fullWidth
                      color='primary'
                      variant='outlined'
                      onClick={() => history.push(`/harmonogram/${departure.id}/general-note/0/add`)}
                      startIcon={<Add />}
                    >
                      Dodaj notatkę
                    </Button>
                    <Button
                      style={{marginTop: '5px'}}
                      fullWidth
                      color='primary'
                      variant='contained'
                      onClick={() => setConfirmationDialog(true)}
                    >
                      Przekaż wyjazd do zamknięcia
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Dialog open={confirmationDialog} onClose={setConfirmationDialog}>
        <DialogContent>
          Czy na pewno chcesz przekazać wyjazd do zamknięcia?
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setConfirmationDialog(false)} color="primary" startIcon={<Close />}>
            Nie
          </Button>
          <Button variant='contained' onClick={closeDeparture} color="primary" autoFocus startIcon={<Check />}>
            Tak
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>

  )
}

export default DepartureOverview
