import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector, clearState } from './UserSlice';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import Header from "../../common/Components/Header";
import NavTabs from "../../common/Components/NavTabs";
import {Box, Fade} from "@material-ui/core";
import {getDepartures} from "../Departures/DeparturesSlice";
import {getVacations} from "../Vacations/VacationsSlice";

const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isFetching, isError } = useSelector(userSelector);
  //
  // useEffect(() => {
  // }, [dispatch])

  useEffect(() => {
    dispatch(getDepartures())
    dispatch(getVacations())

    if (isError) {
      dispatch(clearState());
      history.push('/login');
    }
  }, [isError, dispatch, history]);

  return (
    <div>
      {isFetching ? (
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      ) : (
        <Fragment>
          <Fade in>
            <Box minHeight='100vh'>
              <Header />
              <NavTabs />
            </Box>
          </Fade>
        </Fragment>
      )}
    </div>
  );
};

export default Dashboard;
