import React from "react";
import TableView from './TableView'
import Header from "../../common/Components/Header";

const Scheduler = () => {
  return (
    <div style={{background: '#fff', minHeight: '100vh'}}>
      <Header />
      <TableView />
    </div>
  )
}

export default Scheduler
