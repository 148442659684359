import React, {Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {COLOR} from "../../theme";
import Timetable from "../../features/User/Timetable";
import {Link, Redirect, Route, Switch, useHistory} from "react-router-dom";
import Vacation from "../../features/User/Vacation";
import Departure from "../../views/Departure/Departure";
import {dispatch} from "react-hot-toast";
import {getDepartures} from "../../features/Departures/DeparturesSlice";
import {getVacations} from "../../features/Vacations/VacationsSlice";
import {useDispatch} from "react-redux";
import Scheduler from "../../features/Scheduler/scheduler";

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: COLOR.primary,
  },
})(Tabs);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      style={{height: '100%'}}
      {...other}
    >
      {value === index && (
        <Box height='100%' p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function LinkTab(props) {
  return (
    <Tab
      component={Link}
      to={props.href}
      // onClick={(event) => {
      //   event.preventDefault();
      // }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '100%'
    // backgroundColor: theme.palette.background.paper,
  },
}));

export default function NavTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  useEffect(() => {
    const pathname = history.location.pathname

    if (pathname === '/urlopy') {
      setValue(1)
    } else if (pathname === '/harmonogram') {
      setValue(0)
    }

  }, [history])

  function a11yProps(index, value) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
      style: {
        zIndex: 3,
        fontSize: '14px',
        margin: '8px 16px 0',
        color: value === index ? '#fff' : theme.palette.primary.main,
        fontWeight: 700,
        transition: '300ms color'
      }
    };
  }

  const handleChange = (event, newValue) => {
    if (value === 0) {
      dispatch(getDepartures())
    } else if (value === 1) {
      dispatch(getVacations())
    }
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <StyledTabs
        disableRipple
        disableTouchRipple
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="nav tabs"
        TabIndicatorProps={{style: {backgroundColor: theme.palette.primary.main, height: '48px', borderRadius: '8px 8px 0 0'}}}
      >
        <LinkTab
          label="Harmonogram"
          href="/harmonogram"
          {...a11yProps(0, value)}
        />
        <LinkTab
          label="Urlopy"
          href="/urlopy"
          {...a11yProps(1, value)}
        />
      </StyledTabs>
      <div style={{width: '100%', height: '1px', backgroundColor: theme.palette.primary.main, boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)'}} />

      <Switch>
        <Route exact path='/harmonogram' render={() => {
          return <TabPanel value={value} index={0}>
            <Timetable />
          </TabPanel>
        }}
        />
        <Route path='/urlopy' render={() => {
          return <TabPanel value={value} index={1}>
            <Vacation />
          </TabPanel>
        }}
        />
        <Route path='/planowanie' component={Scheduler} />
        <Route path='/harmonogram/:id' render={() => <Departure />} />
        <Redirect exact from={'/'} to={'/harmonogram'} />
      </Switch>
    </div>
  );
}
