import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from '../features/User/UserSlice';
import { departuresSlice } from "../features/Departures/DeparturesSlice";
import {vacationsSlice} from "../features/Vacations/VacationsSlice";
export default configureStore({
  reducer: {
    user: userSlice.reducer,
    departures: departuresSlice.reducer,
    vacations: vacationsSlice.reducer
  },
});
