import React, {Fragment} from 'react'
import {Box, Button, Grid} from "@material-ui/core";
import {COLOR, VACATIONS_STATUS_COLORS as STATUS_COLORS} from "../../theme";
import {useTheme} from "@material-ui/core/styles";
import {WEEKDAYS} from "../../helpers/utils";
import {format, getISODay, toDate, parseISO} from "date-fns";

const VacationTile = ({record}) => {

  const theme = useTheme()

  return (
    <div style={{background: STATUS_COLORS[record.status], borderRadius: '8px', marginBottom: '24px'}}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <h3 style={{color: '#fff', margin: '5px'}}>
                {
                  record.startDate === record.endDate
                    ? `${WEEKDAYS[getISODay(toDate(parseISO(record.startDate)))]} ${format(toDate(parseISO(record.startDate)), 'dd.MM.yyyy')}`
                    : `${WEEKDAYS[getISODay(toDate(parseISO(record.startDate)))]} ${format(toDate(parseISO(record.startDate)), 'dd.MM.yyyy')} - ${WEEKDAYS[getISODay(toDate(parseISO(record.endDate)))]} ${format(toDate(parseISO(record.endDate)), 'dd.MM.yyyy')}`
                }
              </h3>
            </Grid>
            <Grid item xs={12}>
              <Box bgcolor={theme.palette.background.default} borderRadius='0 0 8px 8px' border={`1px solid ${STATUS_COLORS[record.status]}`}>
                <Grid container>
                  <Grid item xs={6}>
                    <p style={{textAlign: 'left', paddingLeft: '16px', margin: '10px 0 10px'}}>Status</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p style={{textAlign: 'left', paddingLeft: '16px', margin: '10px 0 10px'}}>{record.status}</p>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default VacationTile
